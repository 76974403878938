/* 
Staff Selection component
Stepper 
*/
.MuiStepIcon-root{
    color:#BDBDBD !important;
}

.MuiStepIcon-root.Mui-completed{
    color: #1976d2 !important;
}

.Mui-active{
    color: #1976d2 !important;
}

/* Staff list component*/
.MuiFormLabel-root{
    color:rgba(0, 0, 0, 0.87) !important;
}

.MuiFormLabel-root.Mui-focused{
    color:rgba(0, 0, 0, 0.87) !important;
}

.staff-list{
    padding: 10px 0px 0px 40px;
}

/* Appointment Mode component*/
.appt-mode{
    padding: 15px 0px 0px 40px;
}

/* Apptmt Confirmation component*/
.appt-cnf{
    padding: 30px 0px 0px 60px;
}